import {React,
useState,useEffect }from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { alpha,styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText,
  InputBase,
    Select, Stack } from '@mui/material';
import { useFormik } from "formik";
import {ALL_USERS_LIST_URL, 
   USERS_DISABLE_WITH_FORWARD_URL, 
   } from '../common/ApiUrl';
import { CustomMessage, authHeader } from '../common/mainfunctions';
import API from '../common/API';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import iconForward from "../../images/icon forward email.png"
import * as Yup from "yup";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CustomLoader from '../common/CustomLoader';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
      position: "relative",
      backgroundColor: "#F8F8F8",
      //border: "2px solid #CFCFCF",
      fontSize: 18,
      width: "100%",
      padding: "18px 20px",
      color: "#343434",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  
  const initialValues={
   email:''
  }
export default function ForwardEmailPopup(props){
    const ulist=useSelector((ul)=>ul.UserListReducer)
    const glist=useSelector((gl)=>gl.GroupListReducer)
    const {enqueueSnackbar}=useSnackbar()
    const {open,setYesFun,gid,userid,userPrinName,successcall}=props
    const [items,setItems]=useState([])
    const [itemsInitial,setItemsInitial]=useState([])
    const [loading,setLoading]=useState(false)
    const [forFlag,setForFlag]=useState(false)
      const handleOnSearch = (string, results) => {
        setValues({...values,email:string})
      }
    
      const handleOnHover = (result) => {
        // the item hovered
      }
      const handleOnSelect = (item) => {
        // the item selected
        // setValues(item)
        setValues({...values,email:item.name})

      }
    
      const handleOnFocus = () => {
        console.log('Focused')
      }
    
      const formatResult = (item) => {
        return (
          <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
          </>
        )
      }
const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Give valid email")
      .required("Email is required"),
  });
  
  const handleDisableUser=async(values)=>{
    setLoading(true)
    let findres;
    findres=itemsInitial.filter((r)=>r.name==values.email)
console.log(findres[0].displayName,itemsInitial,values)
    const config={
      method:'GET',
      url:USERS_DISABLE_WITH_FORWARD_URL,
      headers:authHeader(),
      params:{
        disableUserId:userid,
        disableUserPrincipalName:userPrinName,
        emailForwardingStatus:'yes',
        emailForwardingDisplayName:findres[0].displayName,
        emailForwardingUserPricipalName:values.email,
        isAzureUser:findres&&findres.length>0?1:0,
        keepACopy:forFlag,
      }
    }
try{
const response =await API(config)
const {message,status}=response.data
if(status=="Success"){
  CustomMessage(message,'success',enqueueSnackbar)
  successcall()
}
}catch(error){
  console.log(error)
}finally{
  setLoading(false)
}
    }
    const {
      handleSubmit,
      handleChange,
      setValues,
      values,
      errors,
      setErrors,
      touched,
    } = useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: validationSchema,
      onSubmit: (values) =>{
        handleDisableUser(values)
    
      }

    });
    const getAllUsersList=async(search)=>{
      const config={
        method:'GET',
        url:ALL_USERS_LIST_URL,
        headers:authHeader(),
        params:{
          search:search,
        }
      }
  try{
  const response =await API(config)
  const {message,status,data}=response.data
  if(status=="Success"){
    let temp=data.value
    let newColumns
    if(temp){
     newColumns = temp.map( item => {
        const { userPrincipalName: name, ...rest } = item;
        return { name, ...rest }
       }
      );
    }
    setItems(newColumns)
  
    }
  
  }catch(error){
    console.log(error)
  }
    }
    useEffect(()=>{
      getAllUsersList(values.email)
    },[values.email])
   
    const handleChangeFun=()=>{
      setForFlag(!forFlag)
    }
    const getAllUsersListInitial=async(search)=>{
      const config={
        method:'GET',
        url:ALL_USERS_LIST_URL,
        headers:authHeader(),
       
      }
  try{
  const response =await API(config)
  const {message,status,data}=response.data
  if(status=="Success"){
    let temp=data.value
    let newColumns
    if(temp){
     newColumns = temp.map( item => {
        const { userPrincipalName: name, ...rest } = item;
        return { name, ...rest }
       }
      );
    }
    setItemsInitial(newColumns)
  
    }
  
  }catch(error){
    console.log(error)
  }
    }
    useEffect(()=>{
      getAllUsersListInitial()
    },[])
    return(
        <div 
        >
        
        <BootstrapDialog
          onClose={setYesFun}
          aria-labelledby="customized-dialog-title"
          open={open}
          style={{borderRadius:'3% !important',
        padding:'10px !important'}}
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={setYesFun}>
            
          </BootstrapDialogTitle>
          <DialogContent >
            <Box style={{alignItems:'center',marginLeft:"250px"}}>
            <img src={iconForward}/>

            </Box>
           
            <form onSubmit={handleSubmit}>
          
           
          
          <Typography

          style={{fontWeight:'700',
        fontSize:'16px',
    padding:'10px',
textAlign:'center'}}
          >
            Forward to which account?
          </Typography>
      <Typography
      className="login-action"
      sx={{fontWeight:'400',
      fontSize:'14px'}}>
       To whom do you want it to be forwarded?
        </Typography>

        <FormControl
            style={{marginTop:"10px"}}

            sx={{ width: "100%", minWidth: "500px" }}
            variant="standard"
          >
       

              <ReactSearchAutocomplete
              name='email'
            items={items}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            
            autoFocus
            showIcon={false}
            placeholder='Write the user email address'
            styling={{
              backgroundColor:'#F8F8F8',
              borderRadius:'0px',
              height: "64px",
              zIndex: 2,
            }}
            formatResult={formatResult}
          />
           <FormHelperText error={true}>
                {errors.email && touched.email ? errors.email : ""}
              </FormHelperText>
</FormControl>
<FormControl>
<FormControlLabel
                  // className="acc-showtext"
                  // style={{ padding: "10px 2px 2px 82px" }}
                  control={<Checkbox 
                    onChange={handleChangeFun}
                        value={forFlag}
                        checked={forFlag}/>
                      }
                  label="Keep a copy of forwarded email in this mailbox"
                />
              
              </FormControl>   
        <Box sx={{textAlign:'center'}}>

            <Button type="submit" className="login-btn-group-bl" size="medium" 
        
            onClick={handleSubmit}
            >
              {loading&&<CustomLoader loading={loading}/>}
                YES
              </Button>
              <Button  className="login-btn-group" size="medium" 
            style={{
            marginBottom:'5px'
        }}
            onClick={setYesFun}
            >
                NO
              </Button>
        </Box>

            </form>

          </DialogContent>
          
        </BootstrapDialog>
      </div>
    )
}